import React, { useRef } from "react";
import { Route, Routes, Link } from "react-router-dom";
import AboutPage from "../../components/About";
import Projects from "../../components/Projects2";
import LinkBar from "../../components/ui/LinkBar";
import SingleProject from "../../components/SingleProject";


const HomePage = () => {
  const ref = useRef();

  return (
    <>
      <header className="header">
        <section>
          <p>
            Hi! I'm <span id="name">Jon Santiago</span>. I’m a Full Stack
            Developer with a love for community, education and documentary photography based
            in New York City. I channel my visual communication and
            technology interests to find creative solutions and bring my ideas
            online.
          </p>
          <p>
            Feel free to check out my work and resume. If you want to chat,
            don’t hesitate to drop me a line!
          </p>  
        </section>
      </header>

      <section id="section2">
        <LinkBar />
        <Projects />
      </section>
    </>
  );
};

export default HomePage;
