import React from "react";
import { Link } from "react-router-dom";


const SingleProjectNavBar = ({ project }) => {
  return (
    <>
      <nav>
        <ul className="navbar">
          <li>{project.title.toUpperCase()}</li>
          {project.links[1] && (
            <li>
              <a href={project.links[1]}>LIVE SITE</a>
            </li>
          )}
          {project.links[0] && (
            <li>
              <a href={project.links[0]}>GITHUB</a>
            </li>
          )}
          <li>
            <Link to="/">CLOSE</Link>
          </li>
        </ul>
      </nav>
    </>
  );
};

export default SingleProjectNavBar;
