import React from "react";
import { Link, useParams, useHistory } from "react-router-dom";

import SingleProjectNavBar from "./ui/SingleProjectNavBar";
import projects from "../utils/projects";

const SingleProject = () => {
  const projectTitle = useParams();
  const title = projectTitle.id;
  const projectList = projects;
  const projectArr = projectList.filter((project, i) => {
    return i === parseInt(title);
  });

  const [project] = projectArr;


  return (
    <>
      <SingleProjectNavBar project={project} />
      <div className="singleProjectContainer">
        <section className={`singleProjectInfo ${!project.projectImg ? 'singleProjectInfoFull' : ''}`}>
          <p className="singleProjectDescription">{project.description[1]}</p>
          <section className="singleProjectDetails">
            <span className="singleProjectInfoTitle">PROJECT INFO</span>
            <hr className="singleProjectHRTitle"></hr>
            <div className="singleProjectItem">
              <span>Role</span>
              <span className="singleProjectDetail">{project.role}</span>
            </div>
            <hr className="singleProjectHR"></hr>
            <div className="singleProjectItem">
              <span>Year</span>
              <span className="singleProjectDetail">{project.year}</span>
            </div>
            <hr className="singleProjectHR"></hr>
          </section>
        </section>
        {project.imageLink && (
            <img src={project.imageLink} alt={project.projectImgAlt} className="singleProjectImage" />
        )}
      </div>
      {/* <ScrollCarousel images={project.images} /> */}
    </>
  );
};

export default SingleProject;
