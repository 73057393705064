import React from "react";
import { Link } from "react-router-dom";
import resume from "../../assets/Resume.pdf";

import CallMadeIcon from "@mui/icons-material/CallMade";

const LinkBar = () => {
  return (
    <>
      <div className="links">
        <span className="link githubLink">
          <a href="https://github.com/santiagojon">
            <span className="underline">GITHUB</span>
          </a>{" "}
          <CallMadeIcon fontSize="small" />
        </span>
        <span className="link">
          <a href="https://www.linkedin.com/in/santiagojon/">
            {" "}
            <span className="underline">LINKEDIN</span>
          </a>{" "}
          <CallMadeIcon fontSize="small" />
        </span>
        <span className="link">
          {/* <span className="link, underline"> */}
          <a href={resume}>
            {" "}
            <span className="underline">RESUME</span>
          </a>{" "}
          <CallMadeIcon fontSize="small" />
        </span>
        <span className="link">
          <Link to="/about">
            {" "}
            <span className="underline">MORE INFO</span>{" "}
          </Link>
          <CallMadeIcon fontSize="small" />
        </span>
      </div>
    </>
  );
};

export default LinkBar;
