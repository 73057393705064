import React from "react";
import LinkBar from "./ui/LinkBar";
import Technologies from "./Technologies";

import CallMadeIcon from "@mui/icons-material/CallMade";

const AboutPage = () => {
  return (
    <section id="aboutSection">
      <div id="aboutContainer">
      I'm a full stack software engineer based in New York City. Primarily interested in working in news and education tech and in mission-driven 
      organizations, I am currently working at Core Education as a software engineer, after building a career at the intersection of community-based nonprofits, education, design, and journalism.
        <br></br> <br></br>
        Always learning and open to new technologies, here's what I'm currently
        using or have recent experience with:<br></br> <br></br>
      </div>
      <Technologies />
    </section>
  );
};

export default AboutPage;

