import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./assets/index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

import HomePage from "./pages/Home";
import SingleProject from "./components/SingleProject";
import AboutPage from "./components/About";
import ScrollToTop from "./components/ui/ScrollToTop";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<App />} />
        <Route exact path="/project/:id" element={<SingleProject />} />
        <Route exact path="/about" element={<AboutPage />} />
      </Routes>
      {/* <App /> */}
    </BrowserRouter>
  </React.StrictMode>
);

reportWebVitals();
