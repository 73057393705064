import React from "react";

const technologiesRow1 = {
  Languages: "Python, Typescript, Javascript, Swift, C#, HTML, SCSS/CSS, SQL, PHP, bash/Linux",
  UI_Frameworks_and_Tools: "React, Django, Xcode, React Native, Next.js, Node, Jest, React Testing Library, DjangoTest",
  Database: "Postgres, MongoDB, Firebase",
};

const technologiesRow2 = {
  Visualizations: "ARKit, RealityKit, Photogrammetry, Unity, Three.js, React Three Fiber, WebGL, GLSL, Metashape, D3.js",
  Project: "Agile, TDD, Github/Github Lab",
  // Testing: "Jest, React Testing Library",
  Machine_Learning_and_Large_Language_Models: "TensorFlow, ChatGPT APIs",
};

const technologiesRow3 = {
  API: "REST, GraphQL, Postman",
  Styling: "Styled Components, Tailwind, Material UI, React Spring",
  Design: "Figma, Abobe Creative Suite, Wireframing, Prototyping, UX Research",
};

const Technologies = () => {
  return (
    <section id="technologiesContainer">
      <div id="technologiesGrid">
        {/* <h1 id="technologiesTitle">TECHNOLOGIES</h1> */}
        <hr className="techLine"></hr>
        <ul className="techRow">
          {Object.keys(technologiesRow1).map((tech) => {
            let techlist = technologiesRow1[tech];
            if (tech.includes("_")) {
              tech = tech.split("_").join(" ");
            }
            return (
              <div className="techItem">
                <h2 className="techType">{tech}</h2>
                <span className="techList">{techlist}</span>
              </div>
            );
          })}
        </ul>
        <hr className="techLine"></hr>
        <ul className="techRow">
          {Object.keys(technologiesRow2).map((tech) => {
            let techlist = technologiesRow2[tech];
            if (tech.includes("_")) {
              tech = tech.split("_").join(" ");
            }
            return (
              <div className="techItem">
                <h2 className="techType">{tech}</h2>
                <span className="techList">{techlist}</span>
              </div>
            );
          })}
        </ul>
        <hr className="techLine"></hr>
        <ul className="techRow">
          {Object.keys(technologiesRow3).map((tech) => {
            let techlist = technologiesRow3[tech];
            return (
              <div className="techItem">
                <h2 className="techType">{tech}</h2>
                <span className="techList">{techlist}</span>
              </div>
            );
          })}
        </ul>
      </div>
    </section>
  );
};


export default Technologies;
